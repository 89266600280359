<template>
  <v-layout fill-height>
    <v-main style="padding-left:330px;">
      <PageBar
          :title="$tr('admin', 'key_2')"
          :drawer="true"
          :preview="false"
      />
      <Container>
        <Title :title="`${$tr('admin', 'key_94')}`"/>
        <div v-on:keyup.enter="signUp">
            <v-col md="12" sm="12" cols="12">
            <v-text-field
                :label="$tr('auth', 'key_23')"
                outlined
                color="primary"
                type="text"
                v-model="fullname"
            ></v-text-field>
            </v-col>
            <v-col md="12" sm="12" cols="12">
            <v-text-field
                :label="$tr('auth', 'key_5')"
                outlined
                color="primary"
                type="email"
                v-model="email"
            ></v-text-field>
            </v-col>
            <v-col md="12" sm="12" cols="12">
                <v-select
                    :label="$tr('package', 'key_4')"
                    outlined
                    color="primary"
                    dense
                    hide-details
                    :items="Vpackage"
                    v-model="apackage"
                ></v-select>
            </v-col>
            <v-col md="12" sm="12" cols="12">
            <v-text-field
                :label="$tr('auth', 'key_6')"
                outlined
                color="primary"
                :type="!showPassword ? 'password' : 'text'"
                v-model="password"
            >
            <template v-slot:append>
                <v-btn
                    icon
                    width="40"
                    depressed
                    height="40"
                    color="smoke"
                    @click="showPassword = !showPassword"
                >
                <v-icon v-text="!showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"/>
                </v-btn>
            </template>
            </v-text-field>
            </v-col>
            <v-col md="12" sm="12" cols="12">
            <v-text-field
                :label="$tr('auth', 'key_10')"
                outlined
                color="primary"
                :type="!showRePassword ? 'password' : 'text'"
                v-model="rePassword"
            >
            <template v-slot:append>
                <v-btn
                    icon
                    width="40"
                    depressed
                    height="40"
                    color="smoke"
                    @click="showRePassword = !showRePassword"
                >
                <v-icon v-text="!showRePassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"/>
                </v-btn>
            </template>
            </v-text-field>
            </v-col>
            <v-row class="justify-end">
                <v-btn
                    depressed
                    color="primary"
                    :loading="loading"
                    @click="signUp"
                >
                {{ $tr("auth", "key_24") }}
                </v-btn>
            </v-row>
            
        </div>
      </Container>
    </v-main>
  </v-layout>
</template>

<script>
import PageBar from "@/components/blocks/PageBar";
import Container from "@/components/blocks/Container";
import Title from "@/components/blocks/Title";
export default {
  name: 'CustomerCreate',
  components: {
    PageBar,
    Container,
    Title,
  },
  data: () => ({
    fullname:"",
    email: "",
    password: "",
    rePassword: "",
    apackage:"",
    showPassword: false,
    showRePassword: false,
    loading: false
  }),
  computed: {
    Vpackage: function () {
      return [
        {
          text: this.$tr('package', 'key_1'),
          value: this.$tr('package', 'key_1')
        },
        {
          text: this.$tr('package', 'key_2'),
          value: this.$tr('package', 'key_2')
        },
        {
          text: this.$tr('package', 'key_3'),
          value: this.$tr('package', 'key_3')
        },
      ];
    }
  },
  methods: {
    signUp() {
      this.loading = true;
      let params = new URLSearchParams();
      params.append('full_name',this.fullname);
      params.append('email', this.email );
      params.append('package', this.apackage );
      params.append('password', this.password );
      params.append('re_password', this.rePassword );
      this.$http.post(`${this.$serverApiLink}api/auth/create_customer`, params)
      .then(
          response => {
            this.$store.commit('setSnackBar', {
              code: response ? 200 : response.status,
              message: response.data.message
            });
            this.loading = false;
            this.$router.push({name: 'Customers'});
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.loading = false;
          }
      );
    },
    startGoogleLogin(tokenID) {
      this.$store.commit('setLoading', true);
      let params = new URLSearchParams();
      params.append('id_token', tokenID );
      this.$http.post(`${this.$serverApiLink}api/auth/google_in`, params)
          .then(
              response => {
                this.$store.commit('setUser', response.data.user);
                this.$store.commit('setLoading', false);
                this.$router.push({name: 'Apps'});
              }
          ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.$store.commit('setLoading', false);
          }
      );
    }
  },
  mounted() {

  }
}
</script>