<template>
  <v-layout fill-height>
    <v-main style="padding-left:330px;">
      <PageBar
          :title="$tr('menu', 'key_24')"
          :preview="false"
          drawer
      />
      <Container>
        <Title :title="$tr('project', 'key_272')"/>
        <v-row>
          <v-col md="12" sm="12" cols="12">
            <v-text-field
                :label="$tr('admin', 'key_20')"
                outlined
                color="primary"
                dense
                hide-details
                v-model="codemagic_code"
            ></v-text-field>
          </v-col>
          <v-col md="12" sm="12" cols="12">
            <v-text-field
                :label="$tr('admin', 'key_21')"
                outlined
                color="primary"
                dense
                hide-details
                v-model="codemagic_project_id"
            ></v-text-field>
          </v-col>
        </v-row>
      </Container>
      <Footer
          ref="footer"
          :full_width="true"
          :loading="loading"
          @click="updateAction"
      />
    </v-main>
  </v-layout>
</template>

<script>

import PageBar from "@/components/blocks/PageBar";
import Container from "@/components/blocks/Container";
import Title from "@/components/blocks/Title";
import Footer from "@/components/blocks/Footer";

export default {
  name: 'Codemagic',
  components: {
    PageBar,
    Container,
    Title,
    Footer
  },
  data: () => ({
    loading: false,
    codemagic_code: "",
    codemagic_project_id: "",
  }),
  methods: {
    updateAction() {
      this.loading = true;
      let params = new URLSearchParams();
      params.append('codemagic_code', this.codemagic_code);
      params.append('codemagic_project_id', this.codemagic_project_id);
      this.$http.post(`${this.$serverApiLink}api/account/apps/codemagic_update`, params).
      then(
          // eslint-disable-next-line no-unused-vars
          response => {
            this.loading = false;
            this.$refs.footer.showSuccessAlert();
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.loading = false;
          }
      );
    },
    getCodemagicDetails() {
      this.loading = true;
      this.$http.get(`${this.$serverApiLink}api/account/apps/get_codemagic`).
      then(
          response => {
            this.data = response.data.data;
            this.loading = false;
            this.codemagic_code = this.data.code;
            this.codemagic_project_id = this.data.project_id;
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.loading = false;
          }
      );
    }
  },
  beforeMount() {
    // this.email = this.$store.state.user.email;
  },
  mounted() {
    this.getCodemagicDetails();
  }
}
</script>