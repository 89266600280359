import { render, staticRenderFns } from "./Push.vue?vue&type=template&id=5b5a5d6d&"
import script from "./Push.vue?vue&type=script&lang=js&"
export * from "./Push.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAvatar,VBtn,VCard,VCol,VDialog,VLayout,VMain,VRow,VSwitch,VTextField})
