var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"fill-height":""}},[_c('v-main',{staticStyle:{"padding-left":"330px"}},[_c('PageBar',{attrs:{"title":_vm.loading ? _vm.$tr('project', 'key_245') : _vm.ticket.title,"preview":true,"back":""}}),(_vm.loading)?_c('Loader'):_c('Container',[_vm._l((_vm.list),function(comment,index){return _c('v-card',{key:'ticket_admin_comment_'+index,staticClass:"mb-3",staticStyle:{"background":"transparent"},attrs:{"outlined":""}},[_c('div',{staticClass:"pa-4 d-flex justify-space-between align-center"},[_c('div',{staticClass:"d-flex justify-start align-center"},[_c('v-avatar',{staticClass:"mr-3 white--text",attrs:{"size":37,"color":!comment.admin ? 'smoke' : 'primary'}},[_c('div',{staticStyle:{"width":"22px","height":"22px"}},[_c('UserIcon',{attrs:{"size":22}})],1)]),_c('div',[_c('div',{staticClass:"font-weight-medium font-weight-bold"},[_vm._v(" "+_vm._s(!comment.admin ? _vm.$tr('admin', 'key_50') : _vm.$tr('project', 'key_247'))+" ")]),(!comment.admin)?_c('div',{staticClass:"caption smoke--text"},[_vm._v(" "+_vm._s(_vm.user.email)+" ")]):_vm._e()])],1),_c('div',{staticClass:"d-flex justify-start align-center"},[_c('div',{staticClass:"smoke--text mr-2"},[_c('ClockIcon',{attrs:{"size":12}})],1),_c('div',{staticClass:"caption smoke--text"},[_vm._v(" "+_vm._s(comment.created)+" ")])])]),_c('v-divider'),_c('div',{staticClass:"pa-4 body-2",staticStyle:{"white-space":"pre-line"}},[_vm._v(" "+_vm._s(comment.message)+" ")]),(comment.estimation)?_c('div',{staticClass:"pa-4"},[_c('div',{staticClass:"d-flex justify-start align-center"},[_c('v-icon',{attrs:{"color":comment.estimation === 1 ? 'danger' : comment.estimation === 2 ? 'warning' : 'success'},domProps:{"textContent":_vm._s(comment.estimation === 1
                  ? 'mdi-emoticon-confused'
                  : comment.estimation === 2
                    ? 'mdi-emoticon-happy'
                    : 'mdi-emoticon-excited')}}),_c('div',{class:("ml-3 caption " + (comment.estimation === 1
              ? 'danger--text'
              : comment.estimation === 2
                ? 'warning--text'
                : 'success--text'))},[_vm._v(" "+_vm._s(_vm.$tr('project', comment.estimation === 1 ? 'key_248' : comment.estimation === 2 ? 'key_249' : 'key_250'))+" ")])],1)]):_vm._e()],1)}),(_vm.ticket.status !== 2)?_c('v-card',{staticClass:"mb-3",staticStyle:{"background":"transparent"},attrs:{"outlined":""}},[_c('v-row',{staticClass:"pa-4"},[_c('v-col',{attrs:{"md":"12","sm":"12","cols":"12"}},[_c('v-textarea',{attrs:{"label":_vm.$tr('project', 'key_252'),"outlined":"","auto-grow":"","rows":"10","color":"primary","dense":"","hide-details":""},model:{value:(_vm.create.message),callback:function ($$v) {_vm.$set(_vm.create, "message", $$v)},expression:"create.message"}})],1)],1),_c('v-divider'),_c('v-container',{staticClass:"text-right",attrs:{"fluid":""}},[_c('v-btn',{attrs:{"depressed":"","color":"primary","loading":_vm.create.loading},on:{"click":_vm.createComment}},[_vm._v(" "+_vm._s(_vm.$tr('project', 'key_238'))+" ")])],1)],1):_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }