var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-navigation-drawer',{staticStyle:{"padding-top":"8px","padding-bottom":"8px"},attrs:{"relative":"","width":"300","hide-overlay":""},model:{value:(_vm.$store.state.left_drawer),callback:function ($$v) {_vm.$set(_vm.$store.state, "left_drawer", $$v)},expression:"$store.state.left_drawer"}},[_c('div',{staticClass:"d-flex justify-center align-center flex-row mb-3"},[_c('img',{staticClass:"margin_menu",attrs:{"src":_vm.$store.state.config.logo,"height":"34","alt":"Logo"}})]),_c('div',{staticClass:"d-flex flex-row"},[_c('v-tooltip',{attrs:{"right":"","color":"blue_dark"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-5 margin_menu",attrs:{"icon":"","large":"","exact":"","color":"success"},on:{"click":function($event){return _vm.$emit('open_launcher')}}},'v-btn',attrs,false),on),[_c('AddIcon')],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$tr("menu", "key_31")))])]),_vm._l((_vm.items),function(item,index){return _c('div',{key:'app_navigation_'+index},[(index !== 4)?_c('v-tooltip',{attrs:{"right":"","color":"blue_dark"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(index !== 3)?_c('v-btn',_vm._g(_vm._b({staticClass:"mb-5 margin_menu",attrs:{"icon":"","large":"","to":{name: item.to}}},'v-btn',attrs,false),on),[(index === 0)?_c('AppsIcon'):(index === 1)?_c('SendIcon'):(index === 2)?_c('KeySquareIcon'):_vm._e()],1):_vm._e(),(index === 3)?_c('v-btn',_vm._g(_vm._b({staticClass:"mb-5 margin_menu",attrs:{"icon":"","large":"","href":"https://serapisnow.com/support","target":"_blank"}},'v-btn',attrs,false),on),[_c('MessagesIcon')],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$tr("menu", item.name)))])]):_vm._e(),(index === 4 && _vm.$store.state.user.admin)?_c('v-tooltip',{attrs:{"right":"","color":"blue_dark"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-5 margin_menu",attrs:{"icon":"","large":"","to":{name: item.to}}},'v-btn',attrs,false),on),[_c('DashboardIcon')],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$tr("menu", item.name)))])]):_vm._e()],1)}),_c('div',{staticStyle:{"margin-top":"18px","margin-left":"8px"}},[_c('v-avatar',{staticClass:"white--text",staticStyle:{"cursor":"pointer"},attrs:{"color":"smoke","size":26},on:{"click":function($event){return _vm.$router.push({name: 'Profile'})}}},[_c('div',{staticStyle:{"padding-top":"2px"}},[_c('UserIcon',{attrs:{"size":22}})],1)])],1)],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }